'use client'

import { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HrvatskaDanasSutra.css"

const HrvatskaDanasSutra = () => {
    const [infoToday, setInfoToday] = useState('');
    const [infoTomorrow, setInfoTomorrow] = useState('');

    const [newsData, setNewsData] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        appendDots: dots => (
            <div
                style={{
                    borderRadius: "10px",
                    padding: "10px"
                }}
            >
                <ul style={{ marginBottom: "14px" }}> {dots} </ul>
            </div>
        ),
    };

    useEffect(() => {
        const getInfo = async () => {
            try {
                const response = await fetch('api/prognoza');
                const data = await response.json();

                setInfoToday(data.danas);
                setInfoTomorrow(data.sutra);

            } catch (error) {
                console.error('Error fetching sunrise and sunset times:', error);
            }
        };

        const getNewsData = async () => {
            try {
                const response = await fetch(`/api/vijesti`);
                const data = await response.json();

                setNewsData(data);
            } catch (error) {
                console.error('Error fetching news data:', error);
            }
        };

        getInfo();
        getNewsData();
    }, []);

    function sanitizeHtml(html) {
        if (typeof window === 'undefined') {
            return html; // Return original HTML if running on the server side
        }

        const doc = new window.DOMParser().parseFromString(html, 'text/html');
        const elementsToExclude = ['iframe', 'video', 'img'];

        elementsToExclude.forEach((element) => {
            const nodes = doc.getElementsByTagName(element);
            for (let i = nodes.length - 1; i >= 0; i--) {
                const parent = nodes[i].parentNode;
                parent.removeChild(nodes[i]);
            }
        });

        return doc.body.innerHTML;
    }

    const generateNewsPreviews = () => {
        const posts = newsData?.posts || [];
        const newsPreviews = [];

        for (let i = 0; i < 5; i++) {
            const postIndex = i % posts.length;
            const post = posts[postIndex];

            newsPreviews.push(
                <div key={i} className='h-[100%]'>
                    <div className="flex flex-col md:flex-row text-left">
                        <div className='md:w-2/3'>
                            <div className='p-4'>
                                <p className={`text-xl mt-1 inline-block text-white p-2 rounded-lg ${post?.category === 'UPOZORENJE' ? 'bg-red-500' : 'bg-[#0287C2]'}`}>
                                    {post?.category}
                                </p>
                                <p className="text-xl mt-8">{post?.title}</p>
                                <div className='text-sm max-h-[160px] overflow-hidden line-clamp-[12] md:line-clamp-[6] mr-2 mb-2' dangerouslySetInnerHTML={{ __html: sanitizeHtml(post?.summary) }} />
                                <a className="cursor-pointer text-sm text-blue-500 underline" href={`/vijesti/${post?.slug}`}>Pročitaj više</a>
                            </div>
                        </div>
                        <div className="flex items-center justify-center md:w-1/3">
                            <img className="rounded flex items-center justify-center object-cover w-full mb-1 h-[330px]" src={post?.image} alt="News Image" />
                        </div>
                    </div>
                </div>

            );
        }

        return newsPreviews;
    };



    return (
        <article className='md:h-[330px] pl-3'>
            <div className="text-left p-4">
                <h2 className="text-xl mt-1 inline-block text-white p-2 rounded-lg" style={{ backgroundColor: '#0287C2' }}>
                    HRVATSKA - VRIJEME
                </h2>
                <h3 className="text-xl mt-6">Vremenska prognoza za danas</h3>
                <p className='text-sm'>{infoToday}</p>
                <h3 className="text-xl mt-6">Vremenska prognoza za sutra</h3>
                <p className='text-sm'>{infoTomorrow}</p>
            </div>
        </article>
    );
}

export default HrvatskaDanasSutra;