const croatianCities = {
    "Bilogora": { latitude: 45.988, longitude: 17.155 },
    "Bjelovar": { latitude: 45.899, longitude: 16.848 },
    "Cakovec": { latitude: 46.384, longitude: 16.434 },
    "Daruvar": { latitude: 45.591, longitude: 17.225 },
    "Koprivnica": { latitude: 46.164, longitude: 16.833 },
    "Krapina": { latitude: 46.160, longitude: 15.874 },
    "Krizevci": { latitude: 46.022, longitude: 16.541 },
    "Sljeme": { latitude: 45.863, longitude: 15.927 },
    "Sisak": { latitude: 45.488, longitude: 16.376 },
    "Varazdin": { latitude: 46.304, longitude: 16.338 },
    "Zagreb": { latitude: 45.815, longitude: 15.982 },
    "Osijek": { latitude: 45.551, longitude: 18.694 },
    "Pozega": { latitude: 45.327, longitude: 17.807 },
    "SlavonskiBrod": { latitude: 45.160, longitude: 18.016 },
    "Virovitica": { latitude: 45.831, longitude: 17.383 },
    "Vukovar": { latitude: 45.343, longitude: 19.000 },
    "Zupanja": { latitude: 45.078, longitude: 18.697 },
    "Bjelolasica": { latitude: 45.390, longitude: 15.128 },
    "Gospic": { latitude: 44.547, longitude: 15.374 },
    "Karlovac": { latitude: 45.487, longitude: 15.547 },
    "Ogulin": { latitude: 45.267, longitude: 15.229 },
    "Parg": { latitude: 45.376, longitude: 14.400 },
    "Zavizan": { latitude: 44.808, longitude: 14.994 },
    "Biokovo": { latitude: 43.349, longitude: 17.121 },
    "Dinara": { latitude: 44.056, longitude: 16.230 },
    "Gracac": { latitude: 44.300, longitude: 15.697 },
    "Imotski": { latitude: 43.438, longitude: 17.217 },
    "Knin": { latitude: 44.044, longitude: 16.194 },
    "Sinj": { latitude: 43.704, longitude: 16.639 },
    "Umag": { latitude: 45.440, longitude: 13.523 },
    "Rovinj": { latitude: 45.081, longitude: 13.640 },
    "Pula": { latitude: 44.867, longitude: 13.850 },
    "Crikvenica": { latitude: 45.176, longitude: 14.694 },
    "Rijeka": { latitude: 45.343, longitude: 14.409 },
    "Senj": { latitude: 44.990, longitude: 14.905 },
    "Karlobag": { latitude: 44.713, longitude: 14.904 },
    "Krk": { latitude: 45.024, longitude: 14.575 },
    "Cres": { latitude: 44.960, longitude: 14.410 },
    "Rab": { latitude: 44.757, longitude: 14.762 },
    "Losinj": { latitude: 44.530, longitude: 14.468 },
    "Pag": { latitude: 44.447, longitude: 15.059 },
    "Pazin": { latitude: 45.241, longitude: 13.937 },
    "Porec": { latitude: 45.227, longitude: 13.593 },
    "Zadar": { latitude: 44.119, longitude: 15.231 },
    "Sibenik": { latitude: 43.735, longitude: 15.895 },
    "Split": { latitude: 43.5147, longitude: 16.4435 },
    "Ploce": { latitude: 43.058, longitude: 17.438 },
    "Makarska": { latitude: 43.299, longitude: 17.018 },
    "Dubrovnik": { latitude: 42.651, longitude: 18.094 },
    "Kornati": { latitude: 43.770, longitude: 15.372 },
    "Brac": { latitude: 43.325, longitude: 16.651 },
    "Hvar": { latitude: 43.172, longitude: 16.441 },
    "Vis": { latitude: 43.059, longitude: 16.188 },
    "Korcula": { latitude: 42.960, longitude: 17.135 },
    "Lastovo": { latitude: 42.749, longitude: 16.825 },
    "Orebic": { latitude: 42.973, longitude: 17.166 },
    "Mljet": { latitude: 42.739, longitude: 17.549 },
    "Palagruza": { latitude: 42.379, longitude: 16.311 }
};


const GetClosestCroatiaCity = (latitude, longitude, range = 30) => {
    let closestCity = null;
    let minDistance = Infinity;

    for (const city in croatianCities) {
        const cityCoords = croatianCities[city];
        let currentRange = range;

        if (isCoastalCity(city)) {
            currentRange = 17.5; // Set range to 15km for coastal cities
        }

        const distance = calculateDistance(latitude, longitude, cityCoords.latitude, cityCoords.longitude);

        if (distance <= currentRange && distance < minDistance) {
            minDistance = distance;
            closestCity = city;
        }
    }

    return closestCity;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

const isCoastalCity = (city) => {
    const coastalCities = [
        "Rijeka",
        "Senj",
        "Karlobag",
        "Krk",
        "Cres",
        "Rab",
        "Losinj",
        "Pag",
        "Porec",
        "Zadar",
        "Sibenik",
        "Split",
        "Ploce",
        "Makarska",
        "Dubrovnik",
        "Umag",
        "Rovinj",
        "Pula",
        "Crikvenica",
    ];
    return coastalCities.includes(city);
};

export default GetClosestCroatiaCity;