// WeatherBox.jsx

import mapWeatherCodeToIcon from '@/app/utils/CodeToIcon';
import { Icon } from '@iconify/react';
import { countryToAlpha2 } from 'country-to-iso';
import { useRouter } from 'next/navigation';
import React, { useState, useEffect } from 'react';
import LoadingCircle from '../Loading';
import "../../styles/components/weatherbox.css"
import GetClosestCroatiaCity from '@/app/utils/GetClosestCroatiaCity';
import JuggleMaxMinTemp from '@/app/utils/JuggleMaxMinTemp';
import GetSingleCityName from '@/app/utils/GetSingleCityName';

const WeatherBox = ({ country, city, region, isSaved = false }) => {
  const [isLoading, setLoading] = useState(false);
  const [weatherData, setWeatherData] = useState(null);

  const router = useRouter();

  const location = (city === null || country === null) ? "Zagreb,Croatia" : city + "," + country;

  const [maxTemperature, setMaxTemperature] = useState(0);
  const [minTemperature, setMinTemperature] = useState(0);

  const [name, setName] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/api/weather-box?query=${location}`, { cache: "no-cache" });
        const data = await response.json();

        const closestCroatiaCity = GetClosestCroatiaCity(data?.result.data?.nearest_area[0]?.latitude, data?.result.data?.nearest_area[0]?.longitude, 29) || city;

        const jadrankoHourlyResponse = await fetch(`/api/jadranko-satne?query=${closestCroatiaCity}`, { cache: "no-cache" });
        const jadrankoHourlyData = await jadrankoHourlyResponse.json();

        setWeatherData(data.result);

        setMaxTemperature(JuggleMaxMinTemp(data.result, jadrankoHourlyData, 0)[0]);
        setMinTemperature(JuggleMaxMinTemp(data.result, jadrankoHourlyData, 0)[1]);

        const formattedName = await GetSingleCityName(city);
        
        setName(formattedName);

      } catch (error) {
        console.error("Error fetching weather data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <article
      className={`${(!weatherData || !name) ? 'box-pulse' : ''} relative p-3 rounded text-white flex flex-col items-center bg-[#087CB4] cursor-pointer`}
      style={{
        width: '100%',
        maxWidth: '300px',
        transition: 'background-color 0.3s ease',
      }}
      onClick={() => router.push(`/${country.replace("croatia", "hrvatska")}/${city}`.toLowerCase())}
    >
      {(!weatherData || !name) ? (
        <LoadingCircle className='h-[40px] w-[40px]' iconColor="white" />
      ) : (
        <>
          <div className='absolute top-[10px] left-[10px] md:top-[14px] md:left-[14px]'>
            <Icon icon={isSaved ? "material-symbols:star" : "material-symbols:star-outline"} className="text-lg md:text-xl" />
          </div>
          <p className="text-xs md:text-base text-center">{name}, {countryToAlpha2(weatherData?.data?.request[0]?.query.split(', ')[1])}</p>
          <div className="flex items-center">
            <div className="">
              <p className="flex justify-center text-xl md:text-4xl">{`${weatherData?.data?.current_condition[0]?.temp_C}°`}</p>
              <p className="flex justify-center text-xs md:text-base">{`${maxTemperature}°/${minTemperature}°`}</p>
            </div>
            <div className="ml-2">
              <img
                className="h-[40px] w-[40px] md:h-[60px] md:w-[60px]"
                alt="weather icon"
                src={mapWeatherCodeToIcon(weatherData?.data?.current_condition[0]?.weatherCode)}
              />
            </div>
          </div>
          <div className='w-full border-b border-[#FFFFFF66] mx-6 mt-2' />
          <a className='mt-2 text-[12px] hover:underline' href={`/${country.replace("croatia", "hrvatska")}/${city}`.toLowerCase()}>Detaljna prognoza</a>
        </>
      )}
    </article>
  );
};

export default WeatherBox;