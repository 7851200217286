'use client'

import React, { useState, useEffect } from 'react';
import WeatherBox from './WeatherBox';

const WeatherBoxList = () => {
  const [savedCities, setSavedCities] = useState(null);
  const [weatherBoxes, setWeatherBoxes] = useState(null);

  useEffect(() => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('savedCities2')) || [];
      setSavedCities(localStorageData);
    } catch (error) {
      console.warn('Could not retrieve cookies (user disabled?)')
    }
  }, []);

  useEffect(() => {
    if (savedCities !== null) {
      const savedCityNames = savedCities.map(location => location.split(',')[0].toLowerCase());

      const savedWeatherBoxes = savedCities.map((location, index) => {
        const [city, region, country] = location.split(',');
        if (city === null || region === null || country === null) {
          return;
        }
        return <WeatherBox key={index} city={city} region={region} country={country.toLowerCase()} isSaved={true} />;
      });

      let remainingDefaultLocations = defaultLocations.filter(defaultLocation =>
        !savedCityNames.includes(defaultLocation.city.toLowerCase())
      );

      while (savedWeatherBoxes.length < 4 && remainingDefaultLocations.length > 0) {
        const defaultLocation = remainingDefaultLocations.shift();
        savedWeatherBoxes.push(
          <WeatherBox
            key={`default-${savedWeatherBoxes.length}`}
            city={defaultLocation.city}
            region={defaultLocation.region}
            country={defaultLocation.country}
          />
        );
      }

      setWeatherBoxes(savedWeatherBoxes);
    }

  }, [savedCities]);

  const defaultLocations = [
    { city: 'zagreb', country: 'croatia', region: 'grad-zagreb' },
    { city: 'split', country: 'croatia', region: 'splitsko-dalmacija' },
    { city: 'osijek', country: 'croatia', region: 'osjecko-baranjska' },
    { city: 'rijeka', country: 'croatia', region: 'primorsko-goranska' }
  ];

  return (
    <div className="text-white w-full max-w-5xl">
      <section className={`flex grid grid-cols-2 sm:grid-cols-4 w-full gap-1 md:max-w-5xl mt-1`}>
        {weatherBoxes !== null ? weatherBoxes : <></>}
      </section>
    </div>
  );
};

export default WeatherBoxList;
