'use client'

import { useEffect, useState } from "react";

const Greeting = () => {
  // const [strings, setStrings] = useState([]);

  // useEffect(() => {
  //     const getStrings = async () => {
  //       try {
  //         const response = await fetch('api/page-translation?query=home');
  //         const data = await response.json();

  //         setStrings(data.result);
  //       } catch (error) {
  //         console.error('Error fetching string data:', error);
  //       }
  //     };

  //     getStrings();
  //   }, []);

  // const getGreeting = () => {
  //   const currentTime = new Date().getHours();

  //   if (currentTime >= 6 && currentTime < 12) {
  //     return `${strings?.good_morning || ""} :)`;
  //   } else if (currentTime >= 12 && currentTime < 18) {
  //     return `${strings?.good_day || ""} :)`;
  //   } else {
  //     return `${strings?.good_evening || ""} :)`;
  //   }
  // };

  return (
    <h1 className="text-3xl text-center font-semibold mb-4">VREMENSKA PROGNOZA</h1>
  );
}

export default Greeting;