const GetSingleCityName = async (name, nullReturn = false) => {
    const data = await fetch(`/api/single-city-search?query=${name}`);
    const response = await data.json();

    if (response.name){
        return response.name;
    }

    if(nullReturn){
        return null;
    }
    return name;
}

export default GetSingleCityName;