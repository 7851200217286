'use client'

import { Icon } from '@iconify/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import "../styles/components/upozorenjabar.css"
import Link from 'next/link';

const UpozorenjaBar = ({ displayButton }) => {
  const router = useRouter()
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await fetch('/api/bar-warnings');
        if (!response.ok) {
          return;
        }
        const data = await response.json();
        setIcons(data.icons);
      } catch (error) {
        console.error('Error fetching icons:', error);
      }
    }
    fetchIcons();
  }, []);

  return (
    <div className="text-center flex items-center bg-white border-b-2 border-red-600 py-2">
      <div className="flex items-center ml-3 xl:mx-44 md:w-1/2">
        <Icon icon="mingcute:alert-fill" style={{ color: 'rgba(220, 38, 38, 1)', fontSize: '24px' }} />
        <p className="font-semibold text-lg text-red-600 ml-2">Upozorenja</p>
        <a href="https://meteo.hr/" className="ml-2 text-sm text-gray-800 hover:underline">by DHMZ</a>
        <div className="hidden md:flex items-center ml-2 upozorenjabar">
          <div
            className={`${icons.length > 0 ? 'block' : 'hidden'}`}
            style={{
              height: '24px',
              top: '2.4px',
              left: '1.87px',
              borderLeft: '1px solid rgba(52, 58, 64, 1)',
              marginLeft: '16px',
              marginRight: '16px',
            }}
          />
          {icons.map((icon, index) => (
            <Icon
              key={"warning-icon-" + index}
              icon={icon}
              style={{ color: 'rgba(52, 58, 64, 1)', fontSize: '24px', marginRight: index === icons.length - 1 ? '0px' : '4px' }}
            />
          ))}
        </div>
      </div>
      {displayButton && (
        <Link
          className="flex items-center justify-center rounded text-white ml-auto mr-3 xl:mr-44"
          style={{
            height: '30px',
            width: '62px',
            backgroundColor: 'rgba(2, 135, 194, 1)',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            transition: 'background-color 0.2s', // Add the transition property
          }}
          href='/upozorenja'
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#fcba03')}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'rgba(2, 135, 194, 1)')}
        >
          Otvori
        </Link>

      )}
    </div>
  );
};

export default UpozorenjaBar;
